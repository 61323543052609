import FingerprintJS from '@fingerprintjs/fingerprintjs'

class DeviceInfoService {
    async getVisitorData() {
        if (!window.visitorData) {
            const fp = await FingerprintJS.load();
            window.visitorData = await fp.get();
        }

        return window.visitorData;
    }
}


const deviceInfoService = new DeviceInfoService();
export default deviceInfoService;