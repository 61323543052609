import React from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Helmet} from "react-helmet";
import ExternalLinkRender from "./ExternalLinkRender";
import {useSearchParams} from "react-router-dom";
import commonService from "../services/CommonService";

class ThirdPartyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }
    }

    async componentDidMount() {
        const content = await commonService.getThirdPartyContent(this.props.searchParams.get("platform"));
        this.setState({content})
    }

    render() {
        const content = this.state.content;
        const isLoaded = content != null && content !== "";
        return <>
            <Helmet>
                <link rel="stylesheet" href="/css/termsPage.css"/>
            </Helmet>
            {isLoaded ? <ReactMarkdown remarkPlugins={[remarkGfm]}
                                       components={{a: ExternalLinkRender}}>{content}</ReactMarkdown> : <div/>}
            <br/>
            <div className="program__menu">
                <a href="/#contacts">Contact us</a>
            </div>
            <div className="footer">
                <div className="footer__logo"></div>
                <div>E-mail: <a href="mailto:help@tapyou.com">help@tapyou.com</a></div>
                <div>Twitter: <a href="https://twitter.com/tapyouapp" target="_blank" rel="noreferrer">@tapyouapp</a>
                </div>
            </div>
        </>
    }
}

function withParams(Component) {
    return props => <Component {...props} searchParams={useSearchParams()[0]}/>;
}

export default withParams(ThirdPartyPage)