import React from 'react';
import css from './NewUploadMediaState.module.css';

export function NewUploadMediaStateNote(props) {
    return (
        <div>
            {
                props.warning !== null &&
                <div className={css.note}>
                    {props.warning}
                </div>
            }
            {
                props.duplicatesCount > 0 &&
                <div className={css.note}>
                    Some of the files you are trying to upload are duplicates and have not been imported into your TapYou
                </div>
            }
        </div>
    );
}