export function redirectMobile() {
    let userAgent = navigator.userAgent;
    if (userAgent.indexOf("Android") !== -1)
        window.location = "https://tapyou.onelink.me/KGVY/r26cc60w";
    else if (userAgent.indexOf("iPhone") !== -1 || userAgent.indexOf("iPad") !== -1 || userAgent.indexOf("iPod") !== -1)
        window.location = "https://tapyou.onelink.me/PdjF/9tf68lhi";
}

export function generateInviteOneLink() {

    // The initialization code follows the Smart Script code below
    // eslint-disable-next-line no-unused-expressions
    // eslint-disable-next-line no-restricted-globals

    //Initializing Smart Script arguments
    var oneLinkURL = "https://tapyou.onelink.me/E8pt";
    var mediaSource = {defaultValue:"webInvite"};
    var afSub1 = {keys:["roomType"],defaultValue:"Chat"};
    var afSub2 = {keys:["roomId"],defaultValue:"0"};
    var afSub3 = {keys:["token"]};
    var custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};

    //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
    //Onelink URL is generated.
    return window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: oneLinkURL,
        afParameters: {
            mediaSource: mediaSource,
            afSub1: afSub1,
            afSub2: afSub2,
            afSub3: afSub3,
            afCustom: [
                custom_ss_ui
            ]
        }
    });


    // If needed, you can download the script from: https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js

    // See an example of implementation and how to place the URL result behind a CTA on your website: https://appsflyersdk.github.io/appsflyer-onelink-smart-script/examples/utm_parameters.html?utm_campaign=mycmpn&utm_source=mysource

    // See an example of how to display a QR code: https://appsflyersdk.github.io/appsflyer-onelink-smart-script/examples/qr_code.html?inmedia=my_email&incmp=my_campaign

}