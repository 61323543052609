import React from 'react';
import css from './FeedbackPage.module.css';
import {ContactsBox} from '../components/ContactsBox';
import ReCAPTCHA from 'react-google-recaptcha';
import {sendFeedback} from '../utils/coreAPI';
import {Helmet} from "react-helmet";
import AlarmIcon from "../icons/alarm.svg";
import SuccessIcon from "../icons/success.svg";

class FeedbackPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            emailValid: true,
            message: '',
            recaptchaRef: React.createRef(),
            recaptchaValue: null,
            errorSendingFeedback: null,
            successSendingFeedback: false
        };
    }

    handleNameChange(event) {
        const {email, message, recaptchaValue} = this.state;
        this.setState({name: event.target.value});
        this.validateContactUsForm(event.target.value, email, message, recaptchaValue);
    }

    handleEmailChange(event) {
        const {name, message, recaptchaValue} = this.state;
        const email = event.target.value;
        const emailValid = this.validateEmail(email);
        this.setState({email, emailValid});
        this.validateContactUsForm(name, email, message, recaptchaValue);
    }

    handleMessageChange(event) {
        const {name, email, recaptchaValue} = this.state;
        this.setState({message: event.target.value});
        this.validateContactUsForm(name, email, event.target.value, recaptchaValue);
    }

    validateContactUsForm(name, email, message, recaptchaValue) {
        if (name !== ''
            && message !== ''
            && recaptchaValue != null) {
            this.setState({errorSendingFeedback: null});
        } else {
            this.setState({errorSendingFeedback: null});
        }
    }

    validateEmail(email) {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    async handleContactUsClick() {

        const {name, email, message, recaptchaValue} = this.state;
        const emailIsValid = this.validateEmail(email)

        if (!emailIsValid) {
            this.setState({errorSendingFeedback: 'Invalid e-mail'})
            return;
        }

        try {
            const response = await sendFeedback(name, email, message, recaptchaValue);

            if (response.ok) {
                this.setState({successSendingFeedback: true})
            } else {
                this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
            }
        } catch (e) {
            this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
        }
    }

    handleReCaptcha(event) {
        const {name, email, message} = this.state;
        this.setState({recaptchaValue: event});
        this.validateContactUsForm(name, email, message, event);
    }

    render() {
        return (
            <>
                <Helmet>
                    <link rel="stylesheet" href="/css/mobileTermsPage.css"/>
                </Helmet>
                <main className='wrapper'>
                    <main className={css.main}>
                        <div className={css.contactUs} id='contacts'
                             style={this.state.successSendingFeedback ? {display: 'none'} : {}}>
                            <div className={css.contactUsForm}>
                                <div className={css.error}
                                     style={{display: this.state.errorSendingFeedback != null ? 'flex' : 'none'}}>
                                    <img src={AlarmIcon} className={css.errorIcon} alt={'Alarm'}/>
                                    <div className={css.errorText}>{this.state.errorSendingFeedback}</div>
                                </div>
                                <input className={css.input}
                                       type={'text'}
                                       placeholder={'Your name'}
                                       value={this.state.name}
                                       onChange={this.handleNameChange.bind(this)}/>
                                <input className={css.input}
                                       type={'text'}
                                       placeholder={'E-mail'}
                                       value={this.state.email}
                                       style={this.state.emailValid ? {} : {
                                           textDecoration: 'underline',
                                           textDecorationColor: 'red'
                                       }}
                                       onChange={this.handleEmailChange.bind(this)}/>
                                <textarea className={css.textarea}
                                          placeholder={'Message'}
                                          value={this.state.message}
                                          onChange={this.handleMessageChange.bind(this)}/>
                            </div>
                            <div className={css.captcha}>
                                <ReCAPTCHA
                                    ref={this.state.recaptchaRef}
                                    theme={'light'}
                                    onChange={this.handleReCaptcha.bind(this)}
                                    sitekey={'6LeZOeYUAAAAANXOo0sWCX8AoP8uaW063zrd332V'}/>
                            </div>
                            <button
                                className={css.send}
                                onClick={this.handleContactUsClick.bind(this)}
                            >
                                Send
                            </button>
                        </div>
                        <div className={css.successFeedbackWrapper}
                             style={!this.state.successSendingFeedback ? {display: 'none'} : {}}>
                            <div className={css.successFeedbackBox}>
                                <div className={css.successFeedbackTitle}>
                                    THANK YOU <img src={SuccessIcon} className={css.successFeedbackTitleIcon} alt={''}/>
                                </div>
                                <div className={css.successFeedbackMessage}>
                                    Your request has been successfully delivered and we will contact you soon
                                </div>
                            </div>
                        </div>
                    </main>
                    <ContactsBox/>
                </main>
            </>
        );
    }
}

export default FeedbackPage;