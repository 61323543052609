import React, { forwardRef } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { confirmPhone, sendCodeToPhone, verifyPhone } from '../utils/coreAPI';
import { browserName } from "react-device-detect";
import css from './NewLogin.module.css';

/*
// react code input styles
const codeCellStyle = {
    width: '54px',
    height: '52px',
    marginLeft: '6px',
    marginRight: '6px',
    background: '#F3F3F3',
    borderRadius: '7px',
    fontFamily: 'Museo Sans Cyrl',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#373A42',
    outline: 'none',
    border: '2px solid transparent'
};

const codeCellInvalidStyle = {
    width: '54px',
    height: '52px',
    marginLeft: '6px',
    marginRight: '6px',
    background: '#F3F3F3',
    borderRadius: '7px',
    fontFamily: 'Museo Sans Cyrl',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#373A42',
    outline: 'none',
    border: '2px solid #F44C4C'
};
*/

const ForwardedInput = forwardRef(
    (props, ref) => {
        const { onChange, onKeyDown, value } = props;
        return (
            <input
                className={`PhoneInputInput ${props.state.errorMessage === null ? '' : css.input_error}`}
                type="tel"
                autoComplete={browserName.indexOf("Safari") > -1 ? "off" : "off"}
                ref={ref}
                maxLength="17"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
            />
        );
    }
);

class NewLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            country: null,
            phone: '',
            showCodeInput: false,
            showLoginButton: true,
            errorMessage: null,
            code: '',
            // codeRef: React.createRef(),
            sendCodeSecondsLeft: 0,
            apiCall: false,
            confirmEnabled: false
        };
    }

    handleLoggedIn() {
        // const tokenDataValid = tokenService.tokenDataValid();
        // if (tokenDataValid) {
        //     window.location.href = '/app';
        // }
        this.closeAction();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.handleLoggedIn();
    }

    componentDidMount() {
       // / this.handleLoggedIn();
    }

    handleResendTimer(secondsToWait) {
        const waitSeconds = secondsToWait != null ? secondsToWait : 60

        if (waitSeconds > 0) {
            this.setState({ sendCodeSecondsLeft: waitSeconds });
            const timerWrapper = {};

            // create a timer
            timerWrapper.timer = setInterval(() => {
                if (this.state.sendCodeSecondsLeft <= 0) {
                    clearInterval(timerWrapper.timer);
                    this.setState({
                        sendCodeSecondsLeft: 0,
                        errorMessage: null
                    });
                } else {
                    this.setState({ sendCodeSecondsLeft: this.state.sendCodeSecondsLeft - 1 });
                }
            }, 1000);
        } else {
            this.setState({ sendCodeSecondsLeft: 0 });
        }
    }

    isLoginButtonDisabled() {
        return this.state.country === null || this.state.errorMessage !== null;
    }

    async handleLoginButtonClick() {
        if (this.isLoginButtonDisabled()) return;

        if (this.state.phone.length === 0) {
            this.setState({ errorMessage: 'Enter phone number' });
            return;
        }

        // interrupt sending code in case if resend timer not completed
        if (this.state.sendCodeSecondsLeft > 0) {
            return;
        }

        if (this.state.apiCall === true) {
            return;
        }
        this.setState({ apiCall: true });
        const { phone, country } = this.state;
        sendCodeToPhone(`${phone}`, country)
            .then(response => {
                // check resend instructions
                if (response.resendAfter != null) {
                    this.handleResendTimer(response.secondsToWait);
                }

                // too often timer instructions
                if (response.parameters != null && response.parameters.waitTill != null) {
                    this.handleResendTimer(response.parameters.secondsToWait);
                }

                // success case
                if (response.validTill != null) {
                    this.setState({ showCodeInput: true, showLoginButton: false, errorMessage: null, code: '', confirmEnabled: false });

                    // focus input to code input
                    document.getElementsByName('Verification')[0].focus();

                   /* let codeRef = this.state.codeRef.current;
                    if (codeRef && codeRef.textInput[0]) {
                        // https://github.com/40818419/react-code-input/issues/129
                        // https://github.com/40818419/react-code-input/issues/69
                        // https://github.com/40818419/react-code-input/pull/154
                        // https://github.com/40818419/react-code-input/pull/155
                        codeRef.state.input[0] = '';
                        codeRef.state.input[1] = '';
                        codeRef.state.input[2] = '';
                        codeRef.state.input[3] = '';

                        codeRef.textInput[0].onkeypress = (event) => this.handleCodeInputKeyDown(event.key);
                        codeRef.textInput[1].onkeypress = (event) => this.handleCodeInputKeyDown(event.key);
                        codeRef.textInput[2].onkeypress = (event) => this.handleCodeInputKeyDown(event.key);
                        codeRef.textInput[3].onkeypress = (event) => this.handleCodeInputKeyDown(event.key);

                        codeRef.textInput[0].focus();
                        codeRef.textInput[0].select();
                    }*/
                    this.setState({ apiCall: false });
                    return;
                }

                // wrong phone case
                if (response.error === 'TooOften') {
                    this.setState({ errorMessage: 'You are sending sms too often. Please retry later' });
                } else if (response.error === 'InvalidPhone') {
                    this.setState({ errorMessage: 'Invalid phone number' });
                } else if (response.error != null) {
                    this.setState({ errorMessage: response.message });
                } else if (response.error == null) {
                    this.setState({ errorMessage: response });
                }
                this.setState({ apiCall: false });
            })
            .catch(error => {
                if (typeof error == 'string') {
                    this.setState({ errorMessage: error });
                } else if (typeof error != 'string') {
                    this.setState({ errorMessage: error.message });
                } else {
                    this.setState({ errorMessage: 'No internet connection' });
                }
                this.setState({ apiCall: false });
            });
    }

    isConfirmButtonDisabled() {
        return this.state.confirmEnabled === false || this.state.errorMessage !== null;
    }

    async handleConfirmButtonClick() {
        if (this.isConfirmButtonDisabled()) return;

        // confirm code and get access token
        const confirmResponse = await confirmPhone(`${this.state.phone}`, this.state.code);

        if (confirmResponse.auth
            && confirmResponse.auth.access
            && confirmResponse.auth.refresh
            && confirmResponse.profile) {
            localStorage.setItem('userProfile', JSON.stringify(confirmResponse.profile));
            localStorage.setItem('userAuth', JSON.stringify(confirmResponse.auth));

            this.handleLoggedIn();
        }
    }

    async handleCodeInputKeyDown(event) {
        if (event.key === 'Enter' && this.state.confirmEnabled === true) {
            event.preventDefault();
            this.handleConfirmButtonClick();
        }
    }

    async handleLoginKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleLoginButtonClick();
        }
    }

    async handleCodeChange(event) {
        let code = event.target.value
        if (code.length >= 4) {
           code = code.slice(0, 4);
        }
        this.setState({code: code});
        if (code.length === 4) {
            const phone = this.state.phone;
            // verify that phone already registered in tap you
            const verifyResponse = await verifyPhone(`${phone}`, code);

            // error case
            if (verifyResponse.error != null) {
                this.setState({
                    errorMessage: 'Wrong code. Try again'
                });
            } else {
                this.setState({ errorMessage: null, confirmEnabled: true, code: code });
            }
        }
    }

    handlePhoneNumberChange(phone) {
        let phoneNumber = phone == null ? null : parsePhoneNumber(phone)
        this.setState({
            phone,
            country: phoneNumber && phoneNumber.country ? phoneNumber.country.toLowerCase() : null,
            showCodeInput: false,
            showLoginButton: true,
            sendCodeSecondsLeft: 0,
            errorMessage:  phoneNumber && phoneNumber.isPossible() && phoneNumber.isValid() ? null : "Invalid phone number"
        });
    }

    handleEditPhoneButton() {
        this.setState({
            showCodeInput: false,
            showLoginButton: true,
            sendCodeSecondsLeft: 0,
            errorMessage: null,
            code: ''
        });
    }

    closeAction() {
        this.props.closeAction();
    }

    render() {
        return (
            <>
                {/* modal
                для ошибки поля добавлять к .input класс input_error
                если нужен ворнинг тогда показывать блок modal__warning && скрывать блок .modal__label
                .modal__cancel закрывает попап

                желательно вынести модалку в отдельный компонент
                убрать все комментарии от верстки и вынести в отдельную документацию по модальному окну
                */}
                <div className={css.modal}>
                    <div className={css.modal__frame}>
                        <div className={css.modal__section}>

                            {this.props.withCloseButton &&
                                <button className={css.modal__cancel} onClick={this.closeAction.bind(this)}></button>
                            }

                            <div>
                                <div className={css.modal__title}>Log in</div>

                                <div className={css.modal__warning} style={{ display: this.state.errorMessage != null && this.state.phone && this.state.phone.length > 11 ? 'block' : 'none' }}>
                                    <div className={css.modal__warning_text}>{this.state.errorMessage}</div>
                                </div>

                                <form onSubmit={e => e.preventDefault()}>

                                    {!this.state.showCodeInput && 
                                        <div>
                                            <label className={css.modal__label}>What’s your phone number?</label>
                                            <PhoneInput
                                                // country={this.state.country}
                                                inputComponent={ForwardedInput}
                                                state={this.state}
                                                international={true}
                                                value={this.state.phone}
                                                onChange={this.handlePhoneNumberChange.bind(this)}
                                                onKeyDown={this.handleLoginKeyDown.bind(this)}
                                            />
                                        </div>
                                    }

                                    {this.state.showCodeInput &&
                                        <div>
                                            <label className={css.modal__label} >Verification code</label>

                                            <input type={'number'} minLength={0} placeholder={'····'}
                                                   className={`${css.modal__code} ${this.state.errorMessage === null ? '' : css.input_error}`}
                                                   name={'Verification'} value={this.state.code}
                                                   onChange={this.handleCodeChange.bind(this)}
                                                   onKeyDown={this.handleCodeInputKeyDown.bind(this)}/>
                                            {/*<div className={css.modal__code}>*/}
                                            {/*    <ReactCodeInput*/}
                                            {/*        ref={this.state.codeRef}*/}
                                            {/*        type='number'*/}
                                            {/*        fields={4}*/}
                                            {/*        inputStyle={codeCellStyle}*/}
                                            {/*        inputStyleInvalid={codeCellInvalidStyle}*/}
                                            {/*        isValid={this.state.errorMessage === null}*/}
                                            {/*        inputMode={'numeric'}*/}
                                            {/*        name={'Verification'}*/}
                                            {/*        value={this.state.code}*/}
                                            {/*        onChange={this.handleCodeChange.bind(this)}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                    }

                                    <div className={css.modal__description}>
                                        <div className={css.modal__p} style={{ display: this.state.showCodeInput > 0 ? 'block' : 'none' }}>{this.state.phone} <button className={css.modal__link} onClick={this.handleEditPhoneButton.bind(this)}>Edit</button></div>
                                        <div className={css.modal__link} style={{ display: this.state.sendCodeSecondsLeft <= 0 && this.state.showCodeInput ? 'block' : 'none' }}
                                            onClick={this.handleLoginButtonClick.bind(this)}>Resend code</div>
                                        <div className={css.modal__p} style={{ display: this.state.sendCodeSecondsLeft > 0 ? 'block' : 'none' }}>Resend in 0:{String(this.state.sendCodeSecondsLeft).padStart(2, "0")}</div>
                                    </div>

                                    {/* менять текст в кнопке в зависимости от показываемого поля
                                        если это "ввести номер" - Send
                                        если это "код верификации" - Confirm
                                     */}
                                    <div className={css.modal__button}>
                                        {
                                            this.state.showLoginButton ?
                                                <div className={css.modal__button}>
                                                    <button className={`${css.button}`} onClick={this.handleLoginButtonClick.bind(this)}
                                                        data-disabled={this.isLoginButtonDisabled()}>
                                                        <span className={`${css.button__text}`}>Send code</span>
                                                    </button>
                                                </div>
                                                :
                                                <div className={css.modal__button}>
                                                    <button className={`${css.button}`}
                                                        data-disabled={this.isConfirmButtonDisabled()}
                                                        onClick={this.handleConfirmButtonClick.bind(this)}>
                                                        <span className={`${css.button__text}`}>Confirm</span>
                                                    </button>
                                                </div>
                                        }
                                    </div>
                                </form>

                                <div className={css.modal__note}>
                                    By clicking “Send code” you accept
                                    <br />
                                    <a href={"/terms.html"} target='_blank' rel="noopener noreferrer">Terms of Service</a>
                                    <span> and </span>
                                    <a href={"/privacy.html"} target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewLogin;