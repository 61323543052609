import React from 'react';
import css from './NewUploadMediaWindow.module.css';
import Dropzone from 'react-dropzone';
import { extensions } from '../utils/fileUtils';
import { browserName } from "react-device-detect";


export function NewUploadMediaWindow(props) {
    async function handleDropFiles(acceptedFiles) {
        await handleFiles(acceptedFiles);
    }

    async function handleScanFoldersInputChange(event) {
        await handleFiles(Array.from(event.target.files));
    }

    async function handleBrowseFilesInputChange(event) {
        await handleFiles(Array.from(event.target.files));
    }

    async function handleFiles(files) {
        // get extension for each file and sort out not allowed files
        const acceptedFiles = files
            .filter(file => {
                const filenameParts = file.name.split('.');
                const extension = `.${filenameParts[filenameParts.length - 1]}`.toLowerCase();
                const foundExtension = extensions.find(ext => ext === extension);

                return foundExtension != null;
            });

        // clear inputs
        clearInputs();

        // handle new files
        await props.handleNewFiles(files, acceptedFiles);

    }

    function clearInputs() {
        const inputDropzoneElement = document.getElementById('inputDropzone');
        const inputScanElement = document.getElementById('inputScan');
        const inputBrowseElement = document.getElementById('inputBrowse');

        inputDropzoneElement.value = '';
        inputScanElement.value = '';
        inputBrowseElement.value = '';
    }

    return (
        <div className={css.drop}>
            <h3 className={`${css.title}`}>upload photos and videos</h3>
            <div className={css.drop__button}>
                <button className={`${css.drop__button} ${css.button}`}>
                    <label className={`${css.button__text}`}>
                        <input id={'inputScan'}
                            type='file'
                            style={{ display: 'none' }}
                            webkitdirectory={'webkitdirectory'}
                            directory={'directory'}
                            onChange={handleScanFoldersInputChange}
                            {...(browserName.indexOf("Safari") > -1 ? { accept: `..` } : {})}
                        />
                        Choose upload folders
                    </label>
                </button>
            </div>


            <div className={css.drop_area}>
                <Dropzone id={'dropzone'} onDrop={handleDropFiles}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className={css.drop_area_inner}>
                            <input id={'inputDropzone'} {...getInputProps()} aria-label={'Drag & Drop'}/>
                            <div className={`${css.drop_area__title}`}>drag & drop</div>
                            <div className={`${css.drop_area__title} ${css.drop_area__or}`}>or</div>
                            <div className={css.drop_area__button}>
                                <button className={`${css.button}`}>
                                    <div className={`${css.button__text}`}>
                                        <input id={'inputBrowse'}
                                            type='file'
                                            style={{ display: 'none' }}
                                            multiple='multiple'
                                            accept={extensions.join(',')}
                                            onChange={handleBrowseFilesInputChange}
                                            aria-label={'Choose files'}/>
                                        Choose files
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}
                </Dropzone>

            </div>
        </div>
    );
}
