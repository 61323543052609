import React from 'react';
import css from "./NewInvite.module.css";
import logotype from "../images/logotype.svg";
import {Link} from "react-router-dom";
import NewMobileApps from "../components/NewMobileApps";
import {NewContactsBox} from "../components/NewContactsBox";
import {useLocation} from "react-router-dom";

class NewInviteErrorPage extends React.Component {

    render() {
        return (

            <main>

                <div className={css.page}>
                    <div className={css.wrap}>


                        <div className={css.section}>
                            <div className={css.section__wrap}>
                                <div className={css.section__headline}>

                                    {/* logotype START */}
                                    <img className={css.logo} src={logotype} alt="logo"></img>
                                    {/* logotype END */}

                                    <Link className={`${css.link} ${css.headline__link}`} to={"/WhyTapYou"} state={{from: this.props.location.pathname}}>Why TapYou?</Link>
                                </div>

                                <div className={`${css.section_content} ${css.section_content__warn}`}>
                                    <div className={css.note}>
                                        OOPS, Something went WRONG!
                                    </div>
                                    <p className={css.section__p}>Don’t miss new, fun video messages with TapYou
                                        app.</p>
                                </div>

                                <div>
                                    <NewMobileApps storeClass={css.store}/>

                                    <NewContactsBox contactStyle={css.contact}/>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </main>

        )
    }
}

function withParams(Component) {
    return props => <Component {...props} location={useLocation()}/>;
}

export default withParams(NewInviteErrorPage);
