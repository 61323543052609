import {getPublic} from "../utils/api";

class ConfigService {
    constructor() {
        this.getConfig();
    }

    async getConfig() {
        if (this.config == null) {
            this.config = await getPublic("/config.json");
        }

        return this.config;
    }
}

const configService = new ConfigService();
export default configService;