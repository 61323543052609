import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NewInviteRouterPage from "./pages/NewInviteRouterPage";
import NewInviteErrorPage from "./pages/NewInviteErrorPage";
import SharedPage from "./pages/SharedPage";
import SharedExpiredPage from "./pages/SharedExpiredPage";
import TermsPage from "./pages/TermsPage";
import MobileTermsPage from "./pages/MobileTermsPage";
import FeedbackPage from "./pages/FeedbackPage";
import NewLandingPage from "./pages/NewLandingPage";
import WhyTapYouPage from "./pages/WhyTapYouPage";
import ImportPage from "./pages/ImportPage";
import PrivacyPage from "./pages/PrivacyPage";
import MobilePrivacyPage from "./pages/MobilePrivacyPage";
import ThirdPartyPage from "./pages/ThirdPartyPage";
import MobileThirdPartyPage from "./pages/MobileThirdPartyPage";
import FramerFeedbackPage from "./pages/FramerFeedbackPage";
import FramerImportPage from "./pages/FramerImportPage";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                {/*<Route path="/old" element={<LandingPage/>}/>*/}
                <Route path="/" element={<NewLandingPage/>}/>
                <Route path="/whytapyou" element={<WhyTapYouPage/>}/>
                {/*<Route path="/invite" element={<Invite/>}/>*/}
                <Route path="/app" element={<ImportPage/>}/>
                <Route path="/invite" element={<NewInviteRouterPage/>}/>
                {/*<Route path="/invite/group/:groupId" element={<NewInviteGroupPage/>}/>*/}
                {/*<Route path="/invite/chat/:userTag" element={<NewInviteChatPage/>}/>*/}
                <Route path="/inviteError" element={<NewInviteErrorPage/>}/>
                <Route path="/shared/:pageId" element={<SharedPage/>}/>
                <Route path="/webshared/:pageId" element={<SharedPage/>}/>
                <Route path="/sharedExpires" element={<SharedExpiredPage/>}/>
                <Route path="/terms" element={<TermsPage/>}/>
                <Route path="/terms.html" element={<TermsPage/>}/>
                <Route path="/mobile/terms" element={<MobileTermsPage/>}/>
                <Route path="/mobile/terms.html" element={<MobileTermsPage/>}/>
                <Route path="/privacy" element={<PrivacyPage/>}/>
                <Route path="/privacy.html" element={<PrivacyPage/>}/>
                <Route path="/mobile/privacy" element={<MobilePrivacyPage/>}/>
                <Route path="/mobile/privacy.html" element={<MobilePrivacyPage/>}/>
                <Route path="/third-party" element={<ThirdPartyPage/>}/>
                <Route path="/third-party.html" element={<ThirdPartyPage/>}/>
                <Route path="/mobile/third-party" element={<MobileThirdPartyPage/>}/>
                <Route path="/mobile/third-party.html" element={<MobileThirdPartyPage/>}/>
                <Route path="/feedback" element={<FeedbackPage/>}/>
                <Route path="/feedback.html" element={<FeedbackPage/>}/>
                <Route path="/mobile/feedback" element={<FeedbackPage/>}/>
                <Route path="/mobile/feedback.html" element={<FeedbackPage/>}/>
                <Route path="/framer/feedback" element={<FramerFeedbackPage/>}/>
                <Route path="/framer/app" element={<FramerImportPage/>}/>
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
