import React from 'react';
import ReactMarkdown from 'react-markdown'
import {Helmet} from "react-helmet";

class TermsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }
    }

    async componentDidMount() {
        const response = await fetch("/docs/terms.md");
        const content = await response.text();
        this.setState({content})
    }

    render() {
        const content = this.state.content;
        const isLoaded = content != null && content !== "";
        return <>
            <Helmet>
                <link rel="stylesheet" href="/css/mobileTermsPage.css"/>
            </Helmet>
            {isLoaded ? <ReactMarkdown className={"page__inner"}>{content}</ReactMarkdown> : <div/>}
        </>
    }
}

export default TermsPage