class CommonService {

    async getThirdPartyContent(platform) {
        const platformLower = platform?.toLowerCase();
        let path = "/docs/third-party-backend.md";
        let userAgent = navigator.userAgent;
        if (platformLower) {
            if (platformLower === "android") {
                path = "/docs/third-party-android.md";
            } else if (platformLower === "ios") {
                path = "/docs/third-party-ios.md";
            }
        } else if (userAgent.indexOf("Android") !== -1) {
            path = "/docs/third-party-android.md";
        } else if (userAgent.indexOf("iPhone") !== -1 || userAgent.indexOf("iPad") !== -1 || userAgent.indexOf("iPod") !== -1) {
            path = "/docs/third-party-ios.md";
        }
        const response = await fetch(path);
        return await response.text();
    }
}

const configService = new CommonService();
export default configService;