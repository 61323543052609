import React from 'react';
import css from './ContactsBox.module.css';
import TwitterIcon from '../icons/twitter.svg';
import EnvelopeIcon from '../icons/envelope.svg';

export function ContactsBox() {
    return (
        <div className={css.contactsWrapper}>
            <div className={css.contactBox}>
                <img src={EnvelopeIcon} className={css.contactIcon} alt='E-mail'/>
                <a href={'mailto:help@tapyou.com'}>help@tapyou.com</a>
            </div>
            <div className={css.contactBox}>
                <img src={TwitterIcon} className={css.contactIcon} alt='Twitter'/>
                <a href={'https://twitter.com/tapyouapp'} rel='noreferrer' target='_blank'>@tapyouapp</a>
            </div>
        </div>
    );
}
