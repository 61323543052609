import React from 'react';
import ReactMarkdown from 'react-markdown'
import {Helmet} from "react-helmet";
import remarkGfm from "remark-gfm";
import {useSearchParams} from "react-router-dom";
import commonService from "../services/CommonService";

class MobileThirdPartyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }
    }

    async componentDidMount() {
        const content = await commonService.getThirdPartyContent(this.props.searchParams.get("platform"));
        this.setState({content})
    }

    render() {
        const content = this.state.content;
        const isLoaded = content != null && content !== "";
        return <>
            <Helmet>
                <link rel="stylesheet" href="/css/mobileTermsPage.css"/>
            </Helmet>
            {isLoaded ? <ReactMarkdown remarkPlugins={[remarkGfm]} className={"page__inner"}>{content}</ReactMarkdown> : <div/>}
        </>
    }
}

function withParams(Component) {
    return props => <Component {...props} searchParams={useSearchParams()[0]}/>;
}

export default withParams(MobileThirdPartyPage)