import React from 'react';
import css from './NewMobileApps.module.css';

const NewMobileApps = (props) => {
    return (
        <div className={props.storeClass}>
            <a className={`${css.store__link} ${css.store__link_appstore}`}
               href='https://tapyou.onelink.me/PdjF/9tf68lhi'
               target='_blank' rel="noopener noreferrer"> </a>
            <a className={`${css.store__link} ${css.store__link_googleplay}`}
               href='https://tapyou.onelink.me/KGVY/r26cc60w' target='_blank'
               rel="noopener noreferrer"> </a>
        </div>
    )
}

export default NewMobileApps;