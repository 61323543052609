import React from 'react';
import ReactMarkdown from 'react-markdown'
import {Helmet} from "react-helmet";
import MarkdownHeadingRenderer from "../components/MarkdownHeadingRenderer";
import ExternalLinkRender from './ExternalLinkRender';

class PrivacyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }
    }

    async componentDidMount() {
        const response = await fetch("/docs/privacy.md");
        const content = await response.text();
        this.setState({content})
    }

    render() {
        const content = this.state.content;
        const isLoaded = content != null && content !== "";
        return <>
            <Helmet>
                <link rel="stylesheet" href="/css/termsPage.css"/>
            </Helmet>
            {isLoaded ? <ReactMarkdown components = {{
                h2: MarkdownHeadingRenderer,
                h3: MarkdownHeadingRenderer,
                h4: MarkdownHeadingRenderer,
                h5: MarkdownHeadingRenderer,
                h6: MarkdownHeadingRenderer,
                a: ExternalLinkRender
            }}>{content}</ReactMarkdown> : <div/>}
            <div className="program__menu">
                <a href="/#contacts">Contact us</a>
            </div>
            <div className="footer">
                <div className="footer__logo"></div>
                <div>E-mail: <a href="mailto:help@tapyou.com">help@tapyou.com</a></div>
                <div>Twitter: <a href="https://twitter.com/tapyouapp" target="_blank" rel="noreferrer">@tapyouapp</a>
                </div>
            </div>
        </>
    }
}

export default PrivacyPage