import React from 'react';
import {getInviteGroup} from "../utils/coreAPI";
import {Link, useLocation} from "react-router-dom";
import css from "./NewInvite.module.css";
import logotype from "../images/logotype.svg";
import NewMobileApps from "../components/NewMobileApps";
import {NewContactsBox} from "../components/NewContactsBox";

class NewInviteGroupPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: "",
            userName: "",
            groupName: "",
            groupDescription: ""
        };
    }

    componentDidMount() {
        getInviteGroup(this.props.groupId, this.props.token).then(response => {
            if (response.error) {
                this.redirectToError();
            }
            this.setState(response);
        }).catch(error => {
            this.redirectToError();
        });
    }

    redirectToError() {
        window.location = '/inviteError';
    }

    render() {
        return (

            <main>

                <div className={css.page}>
                    <div className={css.wrap}>


                        <div className={css.section}>
                            <div className={css.section__wrap}>
                                <div className={css.section__headline}>

                                    <img className={css.logo} src={logotype} alt="logo"></img>

                                    <Link className={`${css.link} ${css.headline__link}`} to={"/WhyTapYou"} state={{from: this.props.location.pathname + this.props.location.search}}>Why TapYou?</Link>
                                </div>

                                <div className={`${css.section_content} ${css.section_content__group}`}>
                                    <p className={css.section__p}>You Are Invited by {this.state.userName} 🎉</p>
                                    <h3 className={css.section__title}>
                                        {this.state.groupName}
                                        <br/>
                                        {this.state.groupDescription &&
                                            <span>{this.state.groupDescription}</span>}
                                    </h3>
                                </div>

                                <div>
                                    <NewMobileApps storeClass={css.store}/>

                                    <NewContactsBox contactStyle={css.contact}/>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </main>

        )
    }
}

function withParams(Component) {
    return props => <Component {...props} location={useLocation()}/>;
}

export default withParams(NewInviteGroupPage);
