import React from 'react';
import {getInviteChat} from "../utils/coreAPI";
import {Link, useLocation} from "react-router-dom";
import css from "./NewInvite.module.css";
import logotype from "../images/logotype.svg";
import emjFinger from "../images/emj_finger.png";
import NewMobileApps from "../components/NewMobileApps";
import {NewContactsBox} from "../components/NewContactsBox";

class NewInviteChatPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            avatarUrl: ""
        };
    }

    componentDidMount() {
        getInviteChat(this.props.userTag).then(response => {
            if (response.error) {
                this.redirectToError();
            }
            this.setState(response);
        }).catch(error => {
            console.error(error);
            this.redirectToError();
        });
    }

    redirectToError() {
        window.location = '/inviteError';
    }

    render() {
        return (

            <main>

                <div className={css.page}>
                    <div className={css.wrap}>


                        <div className={css.section}>
                            <div className={css.section__wrap}>
                                <div className={css.section__headline}>

                                    <img className={css.logo} src={logotype} alt="logo"></img>

                                    <Link className={`${css.link} ${css.headline__link}`} to={"/WhyTapYou"} state={{from: this.props.location.pathname + this.props.location.search}}>Why TapYou?</Link>
                                </div>

                                <div className={`${css.section_content} ${css.section_content__private}`}>
                                    <img className={css.user_img} src={this.state.avatarUrl ?? '/images/invite-group.png'} alt="avatar"/>
                                    <h3 className={css.section__title}>{this.state.userName}</h3>

                                    <div className={css.section__p__mobile}>
                                        <p className={css.section__p}>I’m here</p>
                                        <img className={css.emj} src={emjFinger} alt="finger"/>
                                    </div>

                                    <p className={`${css.section__p} ${css.section__p__desktop}`}>I added you to my TapYou.<br/>Download the app to make videos together.</p>
                                </div>

                                <div>
                                    <NewMobileApps storeClass={css.store}/>

                                    <NewContactsBox contactStyle={css.contact}/>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </main>

        )
    }
}

function withParams(Component) {
    return props => <Component {...props} location={useLocation()}/>;
}

export default withParams(NewInviteChatPage);
