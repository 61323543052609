import React from 'react';
import css from './NewContactsBox.module.css';

export function NewContactsBox(props) {
    return (
        <div className={props.contactStyle}>
            <a className={`${css.contact__link} ${css.contact__link_mail}`} href={'mailto:help@tapyou.com'} target={'_blank'} rel="noopener noreferrer">help@tapyou.com</a>
            <a className={`${css.contact__link} ${css.contact__link_tw}`} href={'https://twitter.com/tapyouapp'} target={'_blank'} rel="noopener noreferrer">@tapyouapp</a>
        </div>
    );
}
