import React from 'react';
import css from './NewLandingPage.module.css';
import {sendFeedback} from '../utils/coreAPI';
import tokenService from '../services/TokenService';
import {Link} from "react-router-dom";
import NewLogin from '../components/NewLogin';
import logotype from '../images/logotype.svg';
import Modal from "react-modal";
import ReCAPTCHA from "react-google-recaptcha";
import {NewContactsBox} from "../components/NewContactsBox";
import NewMobileApps from "../components/NewMobileApps";
import SuccessIcon from "../icons/success.svg";
import WhyTapYou from "../components/WhyTapYou";
import {useLocation} from "react-router-dom";

class NewLandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contactUsButtonDisabled: true,
            name: '',
            email: '',
            emailValid: true,
            message: '',
            recaptchaRef: React.createRef(),
            recaptchaValue: null,
            loginIsOpen: false,
            errorSendingFeedback: null,
            successSendingFeedback: false
        };
    }

    openLoginModal() {
        const tokenDataValid = tokenService.tokenDataValid();

        if (tokenDataValid) {
            window.location.href = '/app';
        } else {
            this.setState({loginIsOpen: true});
        }
    }

    closeLoginModal() {
        this.setState({loginIsOpen: false});
        const tokenDataValid = tokenService.tokenDataValid();
        if (tokenDataValid) {
            window.location.href = '/app';
        }
    }

    handleNameChange(event) {
        const {email, message, recaptchaValue} = this.state;
        this.setState({name: event.target.value});
        this.validateContactUsForm(event.target.value, email, message, recaptchaValue);
    }

    handleEmailChange(event) {
        const {name, message, recaptchaValue} = this.state;
        const email = event.target.value;
        const emailValid = this.validateEmail(email);
        this.setState({email, emailValid});
        this.validateContactUsForm(name, email, message, recaptchaValue);
    }

    handleMessageChange(event) {
        const {name, email, recaptchaValue} = this.state;
        this.setState({message: event.target.value});
        this.validateContactUsForm(name, email, event.target.value, recaptchaValue);
    }

    validateContactUsForm(name, email, message, recaptchaValue) {
        if (name !== ''
            && message !== ''
            && recaptchaValue != null) {
            this.setState({contactUsButtonDisabled: false, errorSendingFeedback: null});
        } else {
            this.setState({contactUsButtonDisabled: true, errorSendingFeedback: null});
        }
    }

    // https://denis-creative.com/validatsiya-email-s-pomoshhyu-javascript/
    validateEmail(email) {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    async handleContactUsClick() {
        // nothing to do in case if contact button are disabled
        if (this.state.contactUsButtonDisabled) {
            return;
        }

        const {name, email, message, recaptchaValue} = this.state;
        const emailIsValid = this.validateEmail(email)

        if (!emailIsValid) {
            this.setState({errorSendingFeedback: 'Invalid e-mail'})
            return;
        }

        try {
            const response = await sendFeedback(name, email, message, recaptchaValue);

            if (response.ok) {
                this.setState({successSendingFeedback: true})
            } else {
                this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
            }
        } catch (e) {
            this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
        }
    }

    handleReCaptcha(event) {
        const {name, email, message} = this.state;
        this.setState({recaptchaValue: event});
        this.validateContactUsForm(name, email, message, event);
    }

    render() {
        return (

            // .page - страница на сером фоне
            // .section - белые плашки-секции
            // .section__wrap - врапер для плашки секции

            // .button - все типичные кнопки на сайте, можно использовать как с иконкой, так и без нее
            // кнопка располагается на всю ширину, поэтому ей нужен контейнер-родитель, который будет ее ограничивать
            // также кнопка может является отдельным компонентом, который можно вынести отдельно


            <main>

                <div className={css.page}>
                    <div className={css.wrap}>
                        <div className={css.section}>
                            <div className={css.section__wrap}>

                                <div className={css.headline}>

                                    <img className={css.logo} src={logotype} alt="logo"></img>

                                </div>

                                <div className={css.liner}>
                                    <NewMobileApps storeClass={css.store}/>
                                </div>

                                <div className={css.liner}>
                                    <p className={css.liner__text}>
                                        TapYou seamlessly combines footage from you, your family, and friends into a
                                        single video while everyone is capturing their own daily life moments.&nbsp;
                                        <Link className={css.link} to={"/WhyTapYou"}
                                              state={{from: this.props.location.pathname}}>See More</Link>
                                    </p>
                                </div>

                                <div className={css.liner}>
                                    <div className={css.liner__button}>
                                        <button className={`${css.button} ${css.button_gradient}`} onClick={this.openLoginModal.bind(this)}>
                                            <span className={`${css.button__icon} ${css.button__icon_auth}`}></span>
                                            <span className={`${css.button__text} ${css.button__text_white}`}>Log in</span>
                                        </button>
                                    </div>
                                    <br/>
                                    <p className={css.liner__text2}>
                                        Log in to upload your old photos and videos, then watch them instantly as TapYous.
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className={css.whytapyou}>
                            <WhyTapYou className withReturn={false}/>
                        </div>

                        <div className={css.section}>
                            <div className={css.section__wrap}>

                                <div style={this.state.successSendingFeedback ? {display: 'none'} : {}}>
                                <h2 className={`${css.title} ${css.section__title} ${css.title_center}`} id={'contacts'}>Contact Us</h2>

                                <div className={css.modal__warning} style={{display: this.state.errorSendingFeedback != null ? 'block' : 'none'}}>
                                    <div className={css.modal__warning_text}>{this.state.errorSendingFeedback}</div>
                                </div>
                                <form className={css.form} onSubmit={e => e.preventDefault()}>
                                    <input className={`${css.input} ${css.input_text}`} type={"text"} placeholder={'Name'} value={this.state.name} required={true}
                                           onChange={this.handleNameChange.bind(this)}/>
                                    <span>
                                        <input className={`${css.input} ${css.input_text} ${this.state.emailValid ? '' : css.input_error}`} type={"email"} placeholder={'E-mail*'} value={this.state.email}
                                           onChange={this.handleEmailChange.bind(this)}/>
                                        <label>*(fill in if you want to get feedback)</label>
                                    </span>
                                    <textarea className={css.textarea} placeholder={'Message'} value={this.state.message} required={true}
                                              onChange={this.handleMessageChange.bind(this)}></textarea>
                                    <label className={css.form__captcha}>
                                        <ReCAPTCHA
                                            ref={this.state.recaptchaRef}
                                            theme={'light'}
                                            onChange={this.handleReCaptcha.bind(this)}
                                            sitekey={'6LeZOeYUAAAAANXOo0sWCX8AoP8uaW063zrd332V'}/>
                                    </label>
                                    <div className={css.form__button}>
                                        <button className={`${css.button}`} onClick={this.handleContactUsClick.bind(this)}
                                                data-disabled={this.state.contactUsButtonDisabled}>
                                            <span className={`${css.button__text}`}>Send</span>
                                        </button>
                                    </div>


                                    <div className={css.terms}>
                                        By clicking "Send", you agree to and accept our <br/>
                                        <a href={"/terms.html"} target='_blank' rel="noopener noreferrer">Terms of Service</a>
                                        <span> and </span>
                                        <a href={"/privacy.html"} target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                                    </div>

                                </form>
                                </div>
                                <div className={`${css.title} ${css.section__title} ${css.title_center}`}
                                     style={!this.state.successSendingFeedback ? {display: 'none'} : {}}>
                                    <div>
                                        <div>
                                            THANK YOU <img src={SuccessIcon} alt={''}/>
                                        </div>
                                        <div className={css.liner__text}>
                                            Your request has been successfully delivered and we will contact you soon
                                        </div>
                                    </div>
                                </div>

                                <NewContactsBox contactStyle={css.contact}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.loginIsOpen}
                       className={css.modal}
                       style={{overlay: {backgroundColor: 'transparent'}}}
                       ariaHideApp={false}
                       onRequestClose={this.closeLoginModal.bind(this)}>
                    <NewLogin closeAction={this.closeLoginModal.bind(this)} withCloseButton={true}/>
                </Modal>
            </main>
        );
    }
}

function withParams(Component) {
    return props => <Component {...props} location={useLocation()}/>;
}

export default withParams(NewLandingPage);