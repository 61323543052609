import tokenService from "./TokenService";

class FilesDeduplicationService {
    getStorageKey() {
        const userProfile = tokenService.getUserProfile();
        return `uploadedFiles_${userProfile.id}`
    }

    getUploadedFiles() {
        return JSON.parse(sessionStorage.getItem(this.getStorageKey())) || [];
    }

    proceedUpload(importId, items) {
        // prepare uploading files metadata
        const files = items.map(item => { return this.getMeta(item, importId)});

        // put all files as uploaded
        const uploadedFiles = this.getUploadedFiles();
        const newUploadedFiles = uploadedFiles.concat(files)
        sessionStorage.setItem(this.getStorageKey(), JSON.stringify(newUploadedFiles))
    }

    proceedFiltering(items) {
        const uploadedFiles = this.getUploadedFiles();
        let duplicatesCount = 0;
        const newItems = [];

        // walk through all items and try to filter them out by duplicates in uploadedFiles
        items.forEach(item => {
            const meta = this.getMeta(item, null);
            const foundMeta = uploadedFiles.find(file => this.compareMetas(file, meta))

            if (foundMeta != null) {
                duplicatesCount++;
            } else {
                newItems.push(item);
            }
        })

        return {
            duplicatesCount,
            items: newItems
        }
    }

    /**
     * Метод для удаления отмененнных файлов из хранилища сессии
     * @param {*} items - список файлов canceled job
     */
    removeCanceledFiles(items) {
        let canceledSet = new Set()
        items.forEach(item => {
            canceledSet.add(item.filename)
        })
        const uploadedFiles = this.getUploadedFiles();
        let newSetOfFiles = uploadedFiles.filter(
            x => !canceledSet.has(x.filename)
        )
        sessionStorage.setItem(this.getStorageKey(), JSON.stringify(newSetOfFiles))
    }

    /**
     * Метод для удаления файлов импорта
     * @param {*} items - список файлов canceled job
     */
    removeFiles(importId) {
        const uploadedFiles = this.getUploadedFiles();
        const newItems = [];

        // walk through all items and try to filter them out by duplicates in uploadedFiles
        uploadedFiles.forEach(item => {
            if (String(item.importId) !== String(importId)) {
                newItems.push(item);
            } 
        })
        sessionStorage.setItem(this.getStorageKey(), JSON.stringify(newItems))
    }

    async notify(report) {
        // show notification in a separate thread
        if (report.duplicatesCount === 1) {
            alert('Duplicate file. The file you are trying to upload is a duplicate and has not been imported into your TapYou.')
        } else if (report.duplicatesCount > 1) {
            alert('Duplicate files. The files you are trying to upload is a duplicate and has not been imported into your TapYou.')
        }
    }

    getMeta(item, importId) {
        return {
            importId: importId,
            path: item.file.path,
            filename: item.filename,
            filesize: item.filesize
        }
    }

    compareMetas(one, two) {
        return one.path === two.path
            && one.filename === two.filename
            && one.filesize === two.filesize
    }

    clear() {
        sessionStorage.removeItem(this.getStorageKey())
    }
}

const filesDeduplicationService = new FilesDeduplicationService();
window.filesDeduplicationService = filesDeduplicationService;
export default filesDeduplicationService;