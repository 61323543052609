import React from 'react';
import css from './WhyTapYouPage.module.css';
import WhyTapYou from "../components/WhyTapYou";


class WhyTapYouPage extends React.Component {

    render() {
        return (
                <main>
                    <div className={css.page}>
                        <div className={css.wrap}>

                            <WhyTapYou withReturn={true}/>

                        </div>
                    </div>
                </main>
        );
    }
}

export default WhyTapYouPage;