import React from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import {useLocation} from "react-router-dom";
import NewInviteChatPage from "./NewInviteChatPage";
import NewInviteGroupPage from "./NewInviteGroupPage";
import {generateInviteOneLink, redirectMobile} from "../utils/utils";

class NewInviteRouterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomType : this.props.searchParams.get('roomType'),
            roomId : this.props.searchParams.get('roomId'),
            token : this.props.searchParams.get('token'),
            render: false
        }
        if (this.state.roomType !== 'chat' &&  this.state.roomType !== 'group') {
            window.location = '/inviteError';
        }

        try {
            window.location = generateInviteOneLink().clickURL;
        } catch (e) {
            console.log(e);
            redirectMobile();
            this.state.render = true;
        }
    }

    render() {
        return (
            <main>
                {
                    this.state.render && this.state.roomType === 'chat' &&
                    <NewInviteChatPage userTag={this.state.roomId}></NewInviteChatPage>
                }
                {
                    this.state.render && this.state.roomType === 'group' &&
                    <NewInviteGroupPage groupId={this.state.roomId} token={this.state.token}></NewInviteGroupPage>
                }
            </main>
        )
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} searchParams={useSearchParams()[0]} location={useLocation()}/>;
}

export default withParams(NewInviteRouterPage);
