import moment from "moment";
import {tokenExpiresBufferInSeconds} from "../utils/constants";
import {refreshToken} from "../utils/coreAPI";

class TokenService {
    tokenDataExists() {
        const item = localStorage.getItem("userAuth");
        return item != null && item !== "";
    }

    profileDataExists() {
        const item = localStorage.getItem("userProfile");
        return item != null && item !== "";
    }

    validateTokenExpiration(token) {
        // no expiration date - let's mark it as already expired
        if (!token.expires) {
            return false;
        }

        // calculate dates
        const now = moment().add(tokenExpiresBufferInSeconds, 'seconds');
        const expiryDate = moment(token.expires);

        // compare dates
        return expiryDate.unix() > now.unix();
    }

    tokenDataValid() {
        // no user auth found
        if (!this.tokenDataExists()) {
            return false;
        }

        // validate token expiration
        const userAuth = JSON.parse(localStorage.getItem("userAuth"));
        return this.validateTokenExpiration(userAuth.refresh);
    }

    async getToken() {
        // no valid user auth found. Should be redirected to login page
        if (!this.tokenDataValid()) {
            console.log('No valid token found')
            localStorage.clear();
            window.location.href = "/";
        }

        // let's validate tokens
        const userAuth = JSON.parse(localStorage.getItem("userAuth"));
        console.log("ACCESS TOKEN CHECK")
        const accessAreValidExpiration = this.validateTokenExpiration(userAuth.access);
        console.log("REFRESH TOKEN CHECK")
        const refreshAreValidExpiration = this.validateTokenExpiration(userAuth.refresh);

        if (accessAreValidExpiration) {
            // simple case - access token are valid
            return userAuth.access.token;
        } else if (!accessAreValidExpiration && refreshAreValidExpiration) {
            // not a simple case - access token expired, but refresh token are valid
            // let's retrieve an access token for such case
            const refreshResponse = await refreshToken(userAuth.refresh.token);
            if (refreshResponse.access && refreshResponse.refresh) {
                localStorage.setItem("userAuth", JSON.stringify(refreshResponse));
                return refreshResponse.access.token;
            } else {
                // can not retrieve access token by refresh token
                // let's re-login user
                console.log('Can not retrieve access token by refresh token')
                localStorage.clear();
                window.location.href = "/";
            }
        } else {
            // bad case - everything are expired. We have to redirect user to login in such case
            console.log('All tokens are expired')
            localStorage.clear();
            window.location.href = "/";
        }
    }

    getUserProfile() {
        // no valid user auth found. Should be redirected to login page
        if (!this.profileDataExists()) {
            console.log('No valid profile found')
            localStorage.clear();
            window.location.href = "/";
        }

        return JSON.parse(localStorage.getItem('userProfile'))
    }

    logout() {
        localStorage.removeItem("userAuth");
    }
}


const tokenService = new TokenService();
export default tokenService;