import React from 'react';

class ExternalLinkRender extends React.Component {

    render() {
        const isLocal = this.props.href != null && this.props.href.includes("#");
        if (isLocal)
            return (
                <a href={this.props.href} rel="noreferrer">
                    {this.props.children}
                </a>
            );
        else
            return (
                <a href={this.props.href} target="_blank" rel="noreferrer">
                    {this.props.children}
                </a>
            );
    }
}

export default ExternalLinkRender;