import tokenService from "../services/TokenService";
import {completeMultipartUpload} from "./coreAPI.js"
import { FILE_CHUNK_SIZE } from "./fileUtils";
import mapLimit from 'async/mapLimit.js'
import asyncify from 'async/asyncify.js'

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

async function apiCall(authorized, uri, method, body, json = true) {
    const headers = {
        'Content-Type': 'application/json'
    };

    if (authorized) {
        const token = await tokenService.getToken();
        headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(uri, {
        method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    });

    if (response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
        await new Promise(resolve => setTimeout(resolve, 10000)); // wait redirect
    } else if (response.status !== 200) {
        console.log(response);
    }

    const responseText = await response.text()
    if (json && isJsonString(responseText)) {
        return JSON.parse(responseText);
    } else {
        return responseText;
    }
}

async function apiUrlEncodedCall(uri, body, json = false) {
    let formBody = [];
    for (const property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    const response = await fetch(uri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
    })

    if (json) {
        return await response.json(); // parses JSON response into native JavaScript objects
    } else {
        return response;
    }
}

export const getPublic = (uri) => apiCall(false, uri, 'GET', undefined);
export const postPublic = (uri, body) => apiCall(false, uri, 'POST', body);
export const postPublicUrlEncoded = (uri, body) => apiUrlEncodedCall(uri, body);
export const get = (uri) => apiCall(true, uri, 'GET', undefined);
export const post = (uri, body, json = true) => apiCall(true, uri, 'POST', body, json);
export const put = (uri, body) => apiCall(true, uri, 'PUT', body);
export const del = (uri) => apiCall(true, uri, 'DELETE', undefined);

const wait = ms => new Promise(r => setTimeout(r, ms));


const uploadList = [];
let partUploadCount = 0;
let partUploadedCount = 0;
let fileUploadCount = 0;
let fileUploadedCount = 0;
let uploadFinished = false;
let uploadCancelled = false;
let uploading = false;

export const getPartUploadCount = () => { return partUploadCount; }
export const getPartUploadedCount = () => { return partUploadedCount; }
export const getFileUploadCount = () => { return fileUploadCount; }
export const getFileUploadedCount = () => { return fileUploadedCount; }
export const isUploadFinished = () => { return uploadFinished; }
export const isUploadCancelled = () => { return uploadCancelled; }

export const resetUploadList = () => {
    console.log('resetUploadList');
    uploadList.length = 0;
    uploadFinished = false;
    uploadCancelled = true;
    uploading = false;
}

export const errorUploadList = () => {
    console.log('errorUploadList');
    uploadList.length = 0;
    uploadFinished = false;
    uploadCancelled = false;
    uploading = false;
}

export const addToUploadList = (url, file, retries, onProgress, onEnd, isLastPart) => {
    console.log('addToUploadList');
    uploadList.push([url, file, retries, onProgress, onEnd]);
    if (!uploading && (uploadFinished || uploadCancelled)) {
        partUploadCount = 0;
        partUploadedCount = 0;
        fileUploadCount = 0;
        fileUploadedCount = 0;

        uploadFinished = false;
        uploadCancelled = false;
    }
    partUploadCount++;
    if (isLastPart) {
        fileUploadCount++;
    }
}

function until(conditionFunction) {

    const poll = resolve => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 400);
    }

    return new Promise(poll);
}

export const handleUploadList = async () => {
    // if (uploading) return;
    await until(_ => uploading === false);
    uploading = true;

    console.log('handleUploadList');
    const copyUploadList = [...uploadList];
    uploadList.length = 0;
    return mapLimit(copyUploadList, 6, asyncify(async (el) => {
        uploading = true;
        await uploadFile(el[0], el[1], el[2], el[3]).then(async response => {
            if (await el[4](response)) {
                fileUploadedCount++;
                console.log("LAST");
            }
        });
        partUploadedCount++;

        let index = uploadList.indexOf(el);
        if (index !== -1) {
            delete uploadList[index];
        }
    })).then(() => {
        uploading = false;
        copyUploadList.length = 0;
        if (uploadList.length === 0) {
            uploadFinished = true;
            uploadCancelled = false;
        }
    });
}

export const uploadFile = async (url, file, retries, onProgress) => {
    const xhr = new XMLHttpRequest();
    if (window.UploadTasks === null || window.UploadTasks === undefined) {
        window.UploadTasks = []
    }
    window.UploadTasks = [xhr].concat(window.UploadTasks)

    return new Promise((resolve, reject) => {
        xhr.upload.addEventListener("progress", onProgress);
        xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                resolve(xhr.getResponseHeader("etag"));
            } else {
                reject()
            }
        });
        xhr.open("PUT", url, true);
        xhr.send(file);
    }).then(null,
        error => {
            console.log(`RETRY STRATEGY for url - ${url}`)
            if(retries > 0 && xhr.readyState !== 0){
                return wait(3000).then( result => {return uploadFile(url, file, retries - 1, onProgress)})
            }
            else {
                if(xhr.readyState === 0){
                    console.log(`UPLOAD FOR URL ${url} was manually canceled`)
                }else {
                    console.log(`RETRIES EXHAUSTED for ${url}`, error)
                }
                
                throw error
            }
        });
}


export const uploadMultipartFile = async (item, onProgress) => {
    var parts = [];

    for (let i = 0; i < item.uploadUrls.length; i += 1) {
        const ii = i + 1;
        const lastPart = ii === item.uploadUrls.length;
        const start = i * FILE_CHUNK_SIZE;
        const end = ii * FILE_CHUNK_SIZE;
        const blob = item.file.slice(start, end);
        addToUploadList(item.uploadUrls[i], blob, 5, onProgress,  async (response) => {
            console.log(`UPLOADED PART ${i} for ${item.mediaId}`);
            parts.push({
                partNumber: ii,
                etag: JSON.parse(response)
            });

            const lastUploadedPart = parts.length === item.uploadUrls.length;
            if (lastUploadedPart) {
                console.log(`COMPLETE STEP for ${item.mediaId}`);

                await completeMultipartUpload({
                    mediaId: item.mediaId,
                    importId: item.importId,
                    uploadId: item.uploadId,
                    parts: parts
                });
            }

            return lastUploadedPart;
        }, lastPart);
    }
}
